<!-- 页面加载中 动画遮罩 -->
<template>
  <van-overlay z-index="9999" :show="show">
      <div class="wrapper" @click.stop>
        <van-loading />
      </div>
    </van-overlay>
</template>

<script>
export default {
    name: 'overlayLoad',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>